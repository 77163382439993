/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:137d63a4-dcbb-4cdf-ac58-b0936a197653",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Bq1YZMmzA",
    "aws_user_pools_web_client_id": "3jrk6b5qnnper8p4osnaptfa65",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://foe4ryrgy5fxfdo6cvm4awll74.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://cvopa45zi3.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        },
        {
            "name": "jcmobileAPI",
            "endpoint": "https://wkh2c3x6m8.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "f01a4b6158c24f9fbdc960a868368c92",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "f01a4b6158c24f9fbdc960a868368c92",
            "region": "us-east-1"
        }
    },
    "aws_content_delivery_bucket": "jc-mobile-20190818234527-hostingbucket-beta",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://ddy0xjuoytpqt.cloudfront.net",
    "aws_user_files_s3_bucket": "jc-profilebeta-beta",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "jcMap-beta": {
                        "style": "VectorEsriLightGrayCanvas"
                    }
                },
                "default": "jcMap-beta"
            },
            "search_indices": {
                "items": [
                    "jcPeople-beta"
                ],
                "default": "jcPeople-beta"
            }
        }
    },
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "FACEBOOK",
        "GOOGLE"
    ]
};


export default awsmobile;
